const PUBLIC_PAGES = [
  "/",
  "/login",
  "/unauthorized",
  "/checkin",
  "/404",
  "/not-allowed",
];

const AUTHENTICATED_PAGES = ["/dashboard", "/settings", "/reports", "/profile"];

/**
 * NOTE
 * This list must be updated every time we add a new page in NextJS
 * or a new view permission in the DB
 */
const AUTHORIZED_PAGES = {
  "/users": "users.view",
  "/users/add": "users.create",
  "/users/edit/[id]": "users.update",
  "/roles": "roles.view",
  "/roles/add": "roles.create",
  "/roles/edit/[id]": "roles.update",
  "/locations": "locations.view",
  "/locations/add": "locations.create",
  "/locations/edit/[id]": "locations.update",
  "/agencies": "agencies.view",
  "/agencies/add": "agencies.create",
  "/agencies/edit/[id]": "agencies.update",
  "/customers": "customers.view",
  "/customers/add": "customers.create",
  "/customers/edit/[id]": "customers.update",
  "/items": "items.view",
  "/items/add": "items.create",
  "/items/edit/[id]": "items.update",
  "/workers": "workers.view",
  "/workers/add": "workers.create",
  "/workers/import": "workers.create",
  "/workers/imported": "workers.create",
  // "/workers/rates": "workers.bulkupdate",
  "/workers/rates": "workers.update",
  "/workers/skills": "workers.skillsbulkupdate",
  "/workers/edit/[id]": "workers.update",
  "/lines": "productionlines.view",
  "/lines/add": "productionlines.create",
  "/lines/edit/[id]": "productionlines.update",
  "/lines/details/[id]": "linejobs.view",
  "/lines/transfer/[id]": "lines.assignworkers",
  "/jobs": "linejobs.view",
  "/jobs/add": "linejobs.create",
  "/jobs/edit/[id]": "linejobs.update",
  "/jobs/approve": "lines.approveworkershours",
  "/jobs/bulk-checkout/[id]": "linejobs.view",
  "/jobs/import": "lines.importdata",
  "/jobs/bulk-update": "linejobs.update",
  "/timelogs": "timelog.view",
  "/timelogs/add": "timelog.create",
  "/timelogs/edit/[id]": "timelog.update",
  "/reports": "reports.view",
  "/skills": "skills.view",
  "/skills/add": "skills.create",
  "/skills/edit/[id]": "skills.update",
  "/special-rates": "specialrate.view",
  "/special-rates/add": "specialrate.create",
  "/special-rates/edit/[id]": "specialrate.update",
  "/equipments": "equipments.view",
  "/equipments/add": "equipments.create",
  "/equipments/edit/[id]": "equipments.update",
  "/pallets": "pallet.view",
  "/pallets/add": "pallet.create",
  "/pallets/edit/[id]": "pallet.update",
  "/positions": "positions.view",
  "/positions/add": "positions.create",
  "/positions/edit/[id]": "positions.update",
  "/reports/daily-logged": "reports.labordailylogged",
  "/reports/daily-by-location": "reports.dailybylocation",
  "/reports/workers-skill": "reports.workerskills",
  "/reports/daily-movement": "reports.dailymovements",
  "/reports/expected-labor": "reports.laborexpected",
  "/reports/daily-by-customer-item": "reports.dailybyline",
  "/reports/labor-by-line": "reports.laborbyline",
  "/reports/daily-by-agency": "reports.dailybyagency",
  "/reports/daily-by-agency-with-prices": "reports.dailybyagencywithprices",
  "/reports/weekly-by-agency": "reports.weeklybyagency",
  "/reports/workers-list": "reports.workerslist",
  "/reports/expected-production": "reports.productionexpected",
  "/reports/daily-production": "reports.dailypandl",
  "/reports/comparison-pandl": "reports.comparison_pandl",
  "/reports/daily-production-misc": "reports.dailypandlmisc",
  "/reports/overlapped-timelogs": "reports.overlappedtimelogs",
  "/reports/audit": "audit.view",
  "/reports/audit-timelogs": "audit.view",
  "/reports/user-activity": "reports.useractivity",
  "/jobtypes": "jobtype.view",
  "/jobtypes/add": "jobtype.create",
  "/jobtypes/edit/[id]": "jobtype.update",
  "/embedded-dashboard": "reports.powerbi",
  "/itemtypes": "item_type.view",
  "/itemtypes/add": "item_type.create",
  "/itemtypes/edit/[id]": "item_type.update",
};

/**
 * NOTE
 * This list must be updated every time we add a new operation permission
 * in the DB
 */
const AUTHORIZED_OPERATIONS = {
  users: "users.create",
  users: "users.update",
  users: "users.delete",
  roles: "roles.create",
  roles: "roles.update",
  roles: "roles.delete",
  locations: "locations.create",
  locations: "locations.update",
  locations: "locations.delete",
  agencies: "agencies.create",
  agencies: "agencies.update",
  agencies: "agencies.delete",
  customers: "customers.create",
  customers: "customers.update",
  customers: "customers.delete",
  items: "items.create",
  items: "items.update",
  items: "items.delete",
  workers: "workers.create",
  workers: "workers.update",
  workers: "workers.delete",
  lines: "productionlines.create",
  lines: "productionlines.update",
  lines: "productionlines.delete",
  jobs: "linejobs.create",
  jobs: "linejobs.update",
  jobs: "linejobs.delete",
  timelogs: "timelogs.create",
  timelogs: "timelogs.update",
  timelogs: "timelogs.delete",
  skills: "skills.create",
  skills: "skills.update",
  skills: "skills.delete",
  equipments: "equipments.create",
  equipments: "equipments.update",
  equipments: "equipments.delete",
  positions: "positions.create",
  positions: "positions.update",
  positions: "positions.delete",
  jobtypes: "jobtype.create",
  jobtypes: "jobtype.update",
  jobtypes: "jobtype.delete",
  itemtypes: "item_type.create",
  itemtypes: "item_type.update",
  itemtypes: "item_type.delete",
};

const VERSION = "v1.36.0";

export {
  PUBLIC_PAGES,
  AUTHENTICATED_PAGES,
  AUTHORIZED_PAGES,
  AUTHORIZED_OPERATIONS,
  VERSION,
};
