import Button from "react-bootstrap/Button";
import EmbeddedInnerTable from "./EmbeddedInnerTable";
import FiltersButton from "../Filters/FiltersButton";
import GenericViewModal from "./GenericViewModal";
import InnerTable from "./InnerTable";
import InnerStickyTable from "./InnerStickyTable";
import PropTypes from "prop-types";
import TableConfiguration from "./TableConfiguration";
import clsx from "clsx";
import { get } from "lodash";
import styles from "./Table.module.scss";
import { useState, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { useWindowSize } from "../../utils/windowHook";
import SaveFilterModal from "./SaveFilterModal";
import FilterListModal from "./FilterListModal";

/**
 * Use this component to render a table whit built-in styles, pagination, loading,
 * actions column, download as CSV
 */
const Table = ({
  title,
  searchBar,
  filters,
  filtersView,
  filtersData,
  setFilters,
  resetFilters = () => {},
  onCreate,
  embedded = false,
  exportData = true,
  className = "",
  actions = [],
  noActions = false,
  noHeader = false,
  columns,
  showFiltersList,
  ...props
}) => {
  const { t } = useTranslation();

  const basicOptions = [
    {
      name: t("general.details"),
      icon: "bi bi-eye-fill",
      onClick: (row) => {
        setSelectedRow(row);
        setOpenView(true);
      },
      withSubRow: true,
    },
  ];

  const totalActions = noActions ? [] : basicOptions.concat(actions);

  const [openView, setOpenView] = useState(false);

  const [openConfiguration, setOpenConfiguration] = useState(false);

  const [openSaveFiltersForm, setOpenSaveFiltersForm] = useState(false);

  const [openFiltersList, setOpenFiltersList] = useState(false);

  const [shownColumns, setShownColumns] = useState(columns);

  const visibleColumns = shownColumns.filter((c) => !c.hidden);

  const columnsForTable = visibleColumns.filter((c) => !c.forReports);

  const [selectedRow, setSelectedRow] = useState();

  const [openFilter, setOpenFilters] = useState(false);
  const size = useWindowSize();

  useEffect(() => setShownColumns(columns), [columns]);

  const downloadData = async () => {
    const { data, getDataForReport } = props;

    const targetColumns = visibleColumns.filter((c) => !c.hiddeFromReport);

    if (!!getDataForReport) {
      try {
        let targetData = data;
        const response = await getDataForReport();
        //Search response for an array to process results
        Object.keys(response.data).forEach((key) => {
          if (Array.isArray(response.data[key])) {
            targetData = response.data[key];
          }
        });
        getCsvContent(targetColumns, targetData, response.summary);
      } catch (e) {
        console.log(e);
      }
    } else {
      getCsvContent(targetColumns, data, []);
    }
  };

  const getCsvContent = (targetColumns, targetData, summaryData) => {
    const { reduceArrayData = true } = props.reportOptions || {};

    const csvContent =
      "data:text/csv;charset=utf-8," +
      targetColumns.map(({ Header }) => Header.replace(/\n/g, ""));

    targetData.forEach((row) => {
      csvContent += "\n";
      targetColumns.forEach(({ accessor, transformData }) => {
        let cellData = get(row, accessor, "");
        let data = cellData || "";
        if (!!transformData) {
          data =
            Array.isArray(cellData) && reduceArrayData
              ? cellData.reduce(
                  (result, data) => (result += transformData(data, row) + " "),
                  ""
                )
              : transformData(cellData, row);
        }
        data =
          typeof data === "string"
            ? data.replace(/\n/g, "").replace(/\#/g, "")
            : data;
        csvContent += data + ",";
      });
    });

    if (summaryData && summaryData.length) {
      csvContent += "\n";
      summaryData.forEach((row) => {
        csvContent += "\n";
        csvContent += row.label + "," + `"${row.value}"` + ",";
      });
    }

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${title}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  const saveFilter = () => {
    setOpenSaveFiltersForm(true);
  };

  return (
    <main className={clsx(styles.main, className)}>
      {!noHeader && (
        <div
          className={`${styles["table-title-container"]} d-flex flex-column p-0`}
        >
          <div
            className={`${styles.container} border-bottom-none d-flex flex-column flex-md-row`}
          >
            <div className={`${styles["table-header"]} w-100 p-3`}>
              <h2 className={`${styles["table-title"]}`}>{title}</h2>
            </div>
            <div className="w-100 d-flex flex-column flex-md-row justify-content-md-end align-items-start p-3">
              {!!searchBar && searchBar}
              <div
                className={clsx(
                  styles["table-header-buttons"],
                  "d-flex flex-column flex-md-row gap-1 justify-content-evenly ps-0 ps-md-3"
                )}
              >
                {!!filters && (
                  <FiltersButton
                    onChange={() => setOpenFilters(!openFilter)}
                    className={clsx(
                      styles["filters-button"],
                      openFilter && styles["active-filter"]
                    )}
                    size={size}
                  />
                )}
                {exportData && !!props?.data?.length && (
                  <Button
                    variant="secondary"
                    style={{
                      height: 42,
                      minWidth: 130,
                    }}
                    className={`w-100 d-flex align-items-center ${
                      filters && "ms-0 ms-md-3"
                    }`}
                    onClick={downloadData}
                  >
                    <i
                      className={`bi bi-download`}
                      style={{
                        fontSize: 20,
                        paddingTop: 4,
                        marginRight: 8,
                      }}
                    />
                    {t("views.table.export")}
                  </Button>
                )}
                <Button
                  variant="secondary"
                  style={{
                    height: 42,
                    minWidth: 44,
                  }}
                  className={`w-100 d-flex align-items-center ms-0 ms-md-3`}
                  onClick={() => {
                    setOpenConfiguration(!openConfiguration);
                  }}
                >
                  <i
                    className="bi bi-gear-fill me-2 me-md-0"
                    style={{
                      fontSize: 20,
                      paddingTop: 4,
                    }}
                  />
                  <span className="d-block d-md-none">
                    {t("views.table.settings")}
                  </span>
                </Button>
                {(!!filters || showFiltersList) && (
                  <Button
                    variant="secondary"
                    style={{
                      height: 42,
                      minWidth: 44,
                    }}
                    className={`w-100 d-flex align-items-center ms-0 ms-md-3`}
                    onClick={() => {
                      setOpenFiltersList(!openConfiguration);
                    }}
                  >
                    <i
                      className="bi bi-filter me-2 me-md-0"
                      style={{
                        fontSize: 20,
                        paddingTop: 4,
                      }}
                    />
                    <span className="d-block d-md-none">
                      {t("views.table.settings")}
                    </span>
                  </Button>
                )}
              </div>
              {!!onCreate && (
                <Button onClick={onCreate} variant="primary" className="ms-3">
                  {t("general.create")}
                </Button>
              )}
            </div>
          </div>
          <div
            className={styles.filters}
            style={{
              width: "100%",
              display: openFilter ? "block" : "none",
            }}
          >
            <div
              className="bg-dark ps-3 w-100 d-flex justify-content-between align-items-center"
              style={{ height: 48 }}
            >
              <p className="mb-0 fw-bold text-light">
                {t("views.table.filterBy")}
              </p>
              <i
                className="text-light bi bi-x-lg me-3"
                style={{ cursor: "pointer" }}
                onClick={() => setOpenFilters(false)}
              ></i>
            </div>

            <div className="w-100 d-flex flex-column flex-sm-row justify-content-end align-items-center pe-0 pe-sm-3">
              <div
                className="d-flex justify-content-center me-0 me-sm-3 flex-wrap"
                style={{ width: "-webkit-fill-available" }}
              >
                {filters}
              </div>

              <div className="d-flex flex-column py-3">
                <div
                  className={`${styles["reset-container"]} d-flex justify-content-center mb-2`}
                >
                  <Button
                    className="m-3 m-sm-0"
                    variant="secondary"
                    style={{ minWidth: 150 }}
                    onClick={resetFilters}
                  >
                    {t("views.table.reset")}
                  </Button>
                </div>

                <div
                  className={`${styles["reset-container"]} d-flex justify-content-center`}
                >
                  <Button
                    className="m-3 m-sm-0"
                    variant="primary"
                    style={{ minWidth: 150 }}
                    onClick={saveFilter}
                  >
                    {t("views.table.saveFilters")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <GenericViewModal
        open={openView}
        onClose={() => setOpenView(false)}
        fields={visibleColumns}
        data={selectedRow}
      />

      <TableConfiguration
        open={openConfiguration}
        onClose={() => setOpenConfiguration(false)}
        columns={shownColumns}
        onSubmit={(columns) => {
          setShownColumns(columns);
          setOpenConfiguration(false);
        }}
      />

      <SaveFilterModal
        open={openSaveFiltersForm}
        toggleModal={() => setOpenSaveFiltersForm((prev) => !prev)}
        data={filtersData}
      />

      <FilterListModal
        open={openFiltersList}
        toggleModal={() => setOpenFiltersList((prev) => !prev)}
        onApply={(newState) => {
          setFilters({ ...newState, initial: false });
        }}
        view={filtersView}
      />

      {embedded ? (
        <EmbeddedInnerTable
          {...props}
          columns={columnsForTable}
          totalActions={totalActions}
        />
      ) : (
        props.sticky ?
        <InnerStickyTable
          {...props}
          columns={columnsForTable}
          totalActions={totalActions}
        /> :
        <InnerTable
          {...props}
          columns={columnsForTable}
          totalActions={totalActions}
        />
      )}
    </main>
  );
};

Table.propTypes = {
  /** Table's title */
  title: PropTypes.any,

  /** Array of columns each one with their title and accesor (path to access that column's data in a row) */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.any.isRequired,
      accessor: PropTypes.string.isRequired,
      /** Define a custom component to render cells data in the table*/
      Cell: PropTypes.any,
      /** Mehtod used to access data to be displayed. Must return plain text! */
      transformData: PropTypes.func,
      /** Define a custom component to render celss data in the view modal */
      displayInModal: PropTypes.func,
    }).isRequired
  ),

  /** Array of actions that will be rendered in the last columm */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),

  /** Array of styles to be applied by order index to data columns   */
  customCellStyles: PropTypes.array,

  /** Array in which each row  it's an separete object */
  data: PropTypes.array.isRequired,

  /** Search bar component */
  searchBar: PropTypes.element,

  /** Filters component to be displayed on the table's header as a expandable dropdown */
  filters: PropTypes.element,

  /** Function that resets filters to their original state. Required if filters are passed */
  resetFilters: function (props, propName) {
    if (
      !!props["filters"] &&
      (props[propName] == undefined || typeof props[propName] != "function")
    ) {
      return new Error("Please provide a resetFilters function!");
    }
  },

  /** Tells the component to render a loading animation */
  loading: PropTypes.bool.isRequired,

  /** Render a custom message when table's it's empty of data */
  emptyMessage: PropTypes.string,

  /** Pass a function to handle object creation and this will automatically render a  create button
   * besides the filters
   */
  onCreate: PropTypes.func,

  /** Pass a promise that returns  data to be download in built-in report */
  getDataForReport: PropTypes.func,

  /** IMPORTANT: This propertie tells the table to handle it's own pagination when active
   * you should also pass all data (that match your filters) along
   */
  embedded: PropTypes.bool,

  /** Page size used if the component it's handling it's own pagination */
  pageSize: PropTypes.number,

  /** Pagination data in case of using server side pagination */
  paginationData: PropTypes.shape({
    pageSize: PropTypes.number,
    pageCount: PropTypes.number,
    offset: PropTypes.number,
    orderBy: PropTypes.string,
    direction: PropTypes.oneOf(["desc", "asc"]),
  }),

  /** Function that's called on pagination data changes in case of using  server side pagination */
  setPaginationData: PropTypes.func,

  /** Total number of pages in case of using  server side pagination  */
  totalPages: PropTypes.number,

  /** Remove actions from each row and from the header  */
  noActions: PropTypes.bool,

  /** Remove the header container from the table */
  noHeader: PropTypes.bool,

  /** Define maximun width (viewport width number) for table component */
  width: PropTypes.number,
};

export default Table;
